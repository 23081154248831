import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    data: null,
    loading: false,
};
export const paymentSlice = createSlice({
    name: 'paymentDetails',
    initialState,
    reducers: {
        fetchOrderDetailsRequest: (state, _action) => {
            state.loading = true;
        },
        fetchOrderDetailsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        createTransactionRequest: (state, _action) => {
            state.loading = true;
        },
        createTransactionSuccess: (state) => {
            state.loading = false;
        },
    },
});
export const { fetchOrderDetailsRequest, fetchOrderDetailsSuccess, createTransactionRequest, createTransactionSuccess, } = paymentSlice.actions;
export default paymentSlice.reducer;
