import { defineMessages } from 'react-intl';
export default defineMessages({
    master_layout: {
        id: 'app.containers.App.master_layout',
        defaultMessage: 'Master layout',
    },
    login: {
        id: 'app.containers.App.login',
        defaultMessage: 'Login',
    },
    logout: {
        id: 'app.containers.App.logout',
        defaultMessage: 'Logout',
    },
    auth: {
        id: 'app.containers.App.auth',
        defaultMessage: 'Auth',
    },
    scheduling: {
        id: 'app.containers.App.scheduling',
        defaultMessage: 'Scheduling',
    },
    loading: {
        id: 'app.containers.App.loading',
        defaultMessage: 'Loading...',
    },
    something_went_wrong: {
        id: 'app.containers.App.something_went_wrong',
        defaultMessage: 'Something went wrong',
    },
    assessment: {
        id: 'app.containers.App.assessment',
        defaultMessage: 'Assessment',
    },
});
