import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { catchError } from '@utils/sentry';
import { appName } from '@utils/localStorageHelpers';
export let firebaseInstance;
export const initializeFirebase = () => {
    try {
        const config = {
            apiKey: process.env.FIREBASE_API_KEY,
            authDomain: process.env.FIREBASE_API_DOMAIN,
        };
        const app = initializeApp(config, { name: appName });
        const auth = getAuth(app);
        firebaseInstance = {
            firebaseApp: app,
            fireBaseAuth: auth,
        };
        return firebaseInstance;
    }
    catch (error) {
        catchError({ title: 'Firebase initialization', error: error });
        return;
    }
};
export default initializeFirebase;
