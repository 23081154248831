var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { firebaseInstance } from '@utils/firebase';
import { browserLocalPersistence, PhoneAuthProvider, RecaptchaVerifier, setPersistence, signInWithCredential, signInWithCustomToken, signInWithPhoneNumber, } from 'firebase/auth';
import { replace, trim } from 'lodash';
import { AUTH_STORAGE_KEY, localStorageGetItem, QUERY_PARAMS_STORAGE_KEY } from '@utils/localStorageHelpers';
import { signInWithPopup } from 'firebase/auth';
import { socialLoginProviders } from '@contexts/AuthContext/socialAuthProvider';
export const signInPhone = (phoneNumber) => {
    const auth = firebaseInstance === null || firebaseInstance === void 0 ? void 0 : firebaseInstance.fireBaseAuth;
    const appVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        size: 'invisible',
        callback: () => {
            console.log('recaptcha resolved..');
        },
    });
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
};
export const signInCredential = (verificationId, code) => {
    const auth = firebaseInstance === null || firebaseInstance === void 0 ? void 0 : firebaseInstance.fireBaseAuth;
    const credential = PhoneAuthProvider.credential(verificationId, code);
    return signInWithCredential(auth, credential);
};
export const getAllParams = () => {
    const s = window.location.search;
    const search = new URLSearchParams(s);
    const params = {};
    for (const [key, value] of search.entries()) {
        if (key === 'username') {
            params[key] = replace(trim(value), ' ', '+');
        }
        else {
            params[key] = value;
        }
    }
    return params;
};
export function authenticateFirebase(_a) {
    return __awaiter(this, arguments, void 0, function* ({ customToken }) {
        if (!trim(customToken))
            throw new Error('Invalid Access Token');
        yield setPersistence(firebaseInstance.fireBaseAuth, browserLocalPersistence);
        const UC = yield signInWithCustomToken(firebaseInstance.fireBaseAuth, customToken);
        if (UC.user) {
            return yield UC.user.getIdToken();
        }
        throw new Error('Invalid User');
    });
}
export const getStoredSession = () => {
    const tokens = localStorageGetItem(AUTH_STORAGE_KEY);
    return tokens ? JSON.parse(tokens) : {};
};
export const getStoredQueryParams = () => {
    const params = localStorageGetItem(QUERY_PARAMS_STORAGE_KEY);
    return params ? JSON.parse(params) : {};
};
export const initSocialLogin = (socialLoginProviderName) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g;
    const auth = firebaseInstance === null || firebaseInstance === void 0 ? void 0 : firebaseInstance.fireBaseAuth;
    const provider = socialLoginProviders[socialLoginProviderName];
    if (provider) {
        const result = yield signInWithPopup(auth, provider);
        if ((_a = result === null || result === void 0 ? void 0 : result.user) === null || _a === void 0 ? void 0 : _a.getIdToken) {
            const token = yield ((_b = result === null || result === void 0 ? void 0 : result.user) === null || _b === void 0 ? void 0 : _b.getIdToken());
            const user = {
                username: (_c = result === null || result === void 0 ? void 0 : result.user) === null || _c === void 0 ? void 0 : _c.displayName,
                phone: (_d = result === null || result === void 0 ? void 0 : result.user) === null || _d === void 0 ? void 0 : _d.phoneNumber,
                email: (_e = result === null || result === void 0 ? void 0 : result.user) === null || _e === void 0 ? void 0 : _e.email,
                tenantId: (_f = result === null || result === void 0 ? void 0 : result.user) === null || _f === void 0 ? void 0 : _f.tenantId,
                uid: (_g = result === null || result === void 0 ? void 0 : result.user) === null || _g === void 0 ? void 0 : _g.uid,
                idToken: token,
            };
            return user;
        }
        else {
            throw Error('getIdToken not found');
        }
    }
    else {
        throw Error('No provider found on social login, initSocialLogin');
    }
});
export const parseJwt = (token) => {
    if (!token) {
        throw Error('No token provided for parse');
    }
    const base64Url = token.split('.')[1];
    if (base64Url) {
        try {
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join(''));
            return JSON.parse(jsonPayload);
        }
        catch (error) {
            throw Error('Error parsing JWT: ' + (error === null || error === void 0 ? void 0 : error.message));
        }
    }
};
export const parseToken = (accessToken) => {
    var _a, _b, _c;
    const user = parseJwt(accessToken);
    if (user && Object.keys(user).length) {
        const user_id = ((_a = user === null || user === void 0 ? void 0 : user['https://hasura.io/jwt/claims']) === null || _a === void 0 ? void 0 : _a['X-Hasura-User-Id']) || null;
        const tenant_id = ((_b = user === null || user === void 0 ? void 0 : user['https://hasura.io/jwt/claims']) === null || _b === void 0 ? void 0 : _b['X-Hasura-Tenant-Id']) || null;
        const roles = ((_c = user === null || user === void 0 ? void 0 : user['https://hasura.io/jwt/claims']) === null || _c === void 0 ? void 0 : _c['X-Hasura-Allowed-Roles']) || [];
        const expiryTime = (user === null || user === void 0 ? void 0 : user['exp']) || null;
        return { user_id, tenant_id, roles, expiryTime };
    }
    else {
        throw Error('Parsed token no data');
    }
};
export function isTokenExpired(token) {
    if (token) {
        const user = parseJwt(token); //using atob -> atob(token.split('.')[1])
        const expiryTime = (user === null || user === void 0 ? void 0 : user['exp']) || null;
        return Math.floor(new Date().getTime() / 1000) >= expiryTime;
    }
    return true;
}
