import { put, call, takeLatest, fork } from 'redux-saga/effects';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { fetchMeetingSlotsRequest, fetchMeetingSlotsSuccess } from './slice';
import { GET_MEETING_SLOTS } from './queries';
export function* fetchMeetingSlotsWorker({ payload }) {
    var _a, _b, _c, _d;
    try {
        const { meetingId } = payload.data;
        const queryResponse = (yield call(postData, {
            queryString: GET_MEETING_SLOTS,
            payload: { meeting_id: meetingId },
            spreadPayload: true,
        }));
        const meetingSlotsResponse = (_b = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.sch_get_meeting_slots) === null || _b === void 0 ? void 0 : _b[0];
        if (meetingSlotsResponse) {
            yield put(fetchMeetingSlotsSuccess(meetingSlotsResponse.data.slots));
            if ((_c = payload.callback) === null || _c === void 0 ? void 0 : _c.onSuccess) {
                yield call(payload.callback.onSuccess, meetingSlotsResponse.data);
            }
        }
        else {
            throw new Error('An error while fetching meeting slots.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Meeting Slots Worker', error: error });
        if ((_d = payload.callback) === null || _d === void 0 ? void 0 : _d.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
// Watcher saga for fetchMeetingSlotsRequest
export function* watchFetchMeetingSlots() {
    yield takeLatest(fetchMeetingSlotsRequest.type, fetchMeetingSlotsWorker);
}
export function* meetingSlotsRootSaga() {
    yield fork(watchFetchMeetingSlots);
}
