import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '@store/saga';
import * as Sentry from '@sentry/react';
const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer();
export function configureAppStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: ['meta.arg', 'payload.callback'],
            },
        }).concat(sagaMiddleware),
        preloadedState,
        enhancers: (getDefaultEnhancers) => {
            return getDefaultEnhancers().concat(sentryReduxEnhancer);
        },
        devTools: process.env.NODE_ENV !== 'production',
    });
    if (process.env.NODE_ENV !== 'production' && (module === null || module === void 0 ? void 0 : module.hot)) {
        module === null || module === void 0 ? void 0 : module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }
    sagaMiddleware.run(rootSaga);
    return store;
}
export const store = configureAppStore({});
export default store;
