import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthProvider } from '@contexts/AuthContext';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthBook } from '@utils/growthBook';
import { useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const RootLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/login' + location.search);
        }
    }, [location.pathname, navigate]);
    return (_jsx(GrowthBookProvider, { growthbook: growthBook, children: _jsxs(AuthProvider, { children: [_jsx("div", { className: 'h-screen w-screen', children: _jsx(Outlet, {}) }), _jsx(ToastContainer, { style: { width: 'auto' } })] }) }));
};
export default RootLayout;
