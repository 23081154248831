import { fork } from '@redux-saga/core/effects';
import authRootSaga from '@containers/Auth/saga';
import { assessmentRootSaga } from '@containers/Assessment/saga';
import { appRootSaga } from '@containers/App/saga';
import { feedbackRootSaga } from '@containers/Feedback/saga';
import { meetingSlotsRootSaga } from '@containers/Payment/SlotBooking/saga';
import { updateCandidateRootSaga } from '@containers/CandidateRegistration/saga';
import { fetchOrderDetailsRootSaga } from '@containers/Payment/saga';
export default function* rootSaga() {
    yield fork(authRootSaga);
    yield fork(assessmentRootSaga);
    yield fork(appRootSaga);
    yield fork(feedbackRootSaga);
    yield fork(meetingSlotsRootSaga);
    yield fork(updateCandidateRootSaga);
    yield fork(fetchOrderDetailsRootSaga);
}
