import { gql } from '@apollo/client';
export const FETCH_FILE_URL = gql(`
  query file_download_file($id: Int!) {
    file_download_file(id: $id) {
      resource_url
      id
    }
  }
`);
export const UPLOAD_FILE = `
mutation uploadFile($file_type_id: Int!, $original_name: String!, $owner_id: Int!, $extension:String!){
  file_upload_file( file_type_id : $file_type_id , original_name:$original_name ,
  owner_id:  $owner_id,extension: $extension ){
    id
    data
    expires_in
    key
  }
}
`;
export const HEART_BEAT = gql(`
  query heartBeat($payload: [sch_heart_beat_input]!) {
    canx_heart_beat(data: $payload) {
      error_message
      success
    }
  }
`);
