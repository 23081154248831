import { call, fork, takeLatest, select, put } from '@redux-saga/core/effects';
import { selectTestInstanceId } from '@containers/Assessment/selectors';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { createFeedback, storeFeedbackResponse } from './slice';
import { CREATE_FEEDBACK } from './queries';
export function* createFeedbackWorker({ payload }) {
    var _a, _b, _c, _d, _e, _f, _g;
    try {
        const testFeedbackInstanceId = yield select(selectTestInstanceId);
        const response = (yield call(postData, {
            queryString: CREATE_FEEDBACK,
            payload: [
                {
                    rating: (_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.rating,
                    comment: (_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.comment,
                    user_agent: (_c = payload === null || payload === void 0 ? void 0 : payload.data) === null || _c === void 0 ? void 0 : _c.user_agent,
                    instance_id: testFeedbackInstanceId,
                    region: (_d = payload === null || payload === void 0 ? void 0 : payload.data) === null || _d === void 0 ? void 0 : _d.region,
                },
            ],
        }));
        const feedbackResponse = (_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.canx_create_feedback[0];
        if (feedbackResponse) {
            yield put(storeFeedbackResponse({ data: { canx_create_feedback: [feedbackResponse] } }));
            if ((_f = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _f === void 0 ? void 0 : _f.onSuccess) {
                yield call(payload.callback.onSuccess);
            }
        }
        else {
            throw new Error('Feedback creation failed');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Create Feedback Worker',
            error: error,
        });
        if ((_g = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _g === void 0 ? void 0 : _g.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* getCreateFeedbackWatcher() {
    yield takeLatest(createFeedback.type, createFeedbackWorker);
}
export function* feedbackRootSaga() {
    yield fork(getCreateFeedbackWatcher);
}
