import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    request: {
        tcfLoading: false,
        isLoading: true,
    },
    data: {
        assessment: null,
        assessmentSection: null,
        currentQuestion: null,
        questions: null,
        answer: null,
        appState: null,
        testInstance: null,
        testInstanceId: null,
        assessmentSections: [],
        startAssessmentResponse: null,
        startSectionResponse: null,
        next_question_id: null,
        questionsIds: [],
        answers: [],
        currentAssessmentSectionId: undefined,
        parsedMaxSectionTime: null,
        assessmentError: null,
        tcfMeasurement: null,
    },
};
export const assessmentSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {
        initialize: () => {
            return Object.assign(Object.assign({}, initialState), { request: Object.assign(Object.assign({}, initialState.request), { isLoading: true }) });
        },
        startAssessment: (state, action) => {
            state.data.testInstanceId = action.payload.instance_id;
        },
        startAssessmentError: (state, action) => {
            state.data.assessmentError = action.payload.error;
            state.request.isLoading = false;
        },
        storeAssessment: (state, action) => {
            var _a, _b, _c, _d;
            state.data.assessment = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.assessment;
            state.data.currentAssessmentSectionId = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.assessmentSection.id;
            state.data.assessmentSections = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.assessmentSections;
            state.data.startAssessmentResponse = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.startAssessmentResponse;
            state.request.isLoading = false;
        },
        resetSectionState: (state) => {
            state.request.tcfLoading = false;
            state.data.assessmentSection = null;
            state.data.currentQuestion = null;
            state.data.questions = null;
            state.data.answer = null;
            state.data.appState = null;
            state.data.startSectionResponse = null;
            state.data.next_question_id = null;
            state.data.questionsIds = [];
            state.data.answers = [];
            state.data.currentAssessmentSectionId = undefined;
            state.data.parsedMaxSectionTime = null;
            state.data.tcfMeasurement = null;
        },
        startSection: (_state, _action) => { },
        storeSection: (state, action) => {
            state.data.assessmentSection = action.payload.assessmentSection;
            state.data.startSectionResponse = action.payload.startSectionResponse;
            state.data.next_question_id = action.payload.next_question_id;
            state.data.questionsIds = action.payload.questionsIds;
            state.data.parsedMaxSectionTime = action.payload.parsedMaxSectionTime;
            state.data.answers = action.payload.answers;
        },
        submitSection: (_state, _action) => { },
        storeStopSection: (state, action) => {
            state.data.startSectionResponse = action.payload.startSectionResponse;
        },
        fetchNextQuestionById: (state, _action) => {
            state.data.answer = null;
        },
        fetchNextQuestionFromCollection: (state, _action) => {
            state.data.answer = null;
        },
        storeNextQuestion: (state, action) => {
            state.data.currentQuestion = action.payload.data;
            state.data.next_question_id = action.payload.next_question_id;
        },
        fetchQuestionsList: () => { },
        storeQuestionList: (state, action) => {
            state.data.questions = action.payload.data;
        },
        saveAnswer: (_state, _action) => { },
        storeAnswer: (state, action) => {
            state.data.answer = action.payload;
            state.data.answers.push(action.payload);
        },
        storeBulkAnswer: (state, action) => {
            state.data.answers = action.payload;
        },
        stopSection: (_state, _action) => { },
        fetchTcfMeasure: (state, _action) => {
            state.request.tcfLoading = true;
        },
        saveAppState: (_state, _action) => { },
        storeAppState: (state, action) => {
            state.data.appState = action.payload;
        },
        getAppState: (_state, _action) => { },
        storeTcfMeasurement: (state, action) => {
            state.request.tcfLoading = false;
            state.data.tcfMeasurement = action.payload;
        },
        fetchTestInstance: (_state, _action) => { },
        saveBulkAnswer: (_state, _action) => { },
    },
});
export const { initialize, startAssessment, startSection, resetSectionState, submitSection, fetchNextQuestionById, fetchQuestionsList, saveAnswer, stopSection, fetchTcfMeasure, saveAppState, storeAssessment, storeSection, storeNextQuestion, storeQuestionList, storeAnswer, storeAppState, getAppState, storeStopSection, fetchTestInstance, startAssessmentError, storeTcfMeasurement, fetchNextQuestionFromCollection, saveBulkAnswer, storeBulkAnswer, } = assessmentSlice.actions;
export default assessmentSlice.reducer;
