import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from 'react-router-dom';
import RootLayout from './RootLayout';
import RootErrorPage from './RootErrorPage';
import { Logout } from '@containers/Auth/Logout';
import Login from '@components/Login/Loadable';
import { Redirect } from '@containers/Auth/Redirect';
import PlayGround from '@containers/Assessment/PlayGround/Loadable';
import Feedback from '@containers/Feedback/Loadable';
import Assessment from '@containers/Assessment/Loadable';
import Submission from '@containers/Assessment/Submission/Loadable';
import Instructions from '@containers/Assessment/Instructions/Loadable';
import Invite from '@containers/Assessment/Invite/Loadable';
import AssessmentError from '@containers/Assessment/AssessmentError/Loadable';
import LoginContainer, { AuthRedirectLoadable } from '@containers/Auth/Loadable';
import VerifyOtp from '@components/Login/VerifyOtp/Loadable';
import AssessmentSubmission from '@containers/Assessment/AssessmentSubmission/Loadable';
import Thankyou from '@containers/Thankyou/Loadable';
import PaymentLayout from '@containers/Payment/Loadable';
import PaymentDetails from '@containers/Payment/PaymentDetails/Loadable';
import PaymentSummary from '@containers/Payment/PaymentSummary/Loadable';
import ScheduleNow from '@containers/Payment/SlotBooking/Loadable';
import BookingConfirm from '@containers/Payment/BookingConfirm/Loadable';
import BookingReSchedule from '@containers/Payment/RescheduledBooking/Loadable';
import CancellationWindowClosed from '@containers/Payment/ErrorScreens/CancellationWindowClosed/Loadable';
import RescheduleWindowClosed from '@containers/Payment/ErrorScreens/RescheduleWindowClosed/Loadable';
import BookingWindowClosed from '@containers/Payment/ErrorScreens/BookingWindowClosed/Loadable';
import SessionAlreadyCompleted from '@containers/Payment/ErrorScreens/SessionAlreadyCompleted/Loadable';
import BookingWindowCancelled from '@containers/Payment/ErrorScreens/BookingWindowCancelled/Loadable';
import CancelBookingContainer from '@containers/Payment/CancelBooking/Loadable';
import RegisterCandidate from '@containers/CandidateRegistration/Loadable';
// import { AsyncAnswer } from '@components/AsyncAnswer';
// import { HardWareTest } from '@containers/HardwareTest';
const router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(RootLayout, {}),
        errorElement: _jsx(RootErrorPage, {}),
        children: [
            {
                lazy: LoginContainer,
                children: [
                    {
                        path: 'login',
                        lazy: Login,
                    },
                    {
                        path: 'verify',
                        lazy: VerifyOtp,
                    },
                ],
            },
            {
                lazy: AuthRedirectLoadable,
                children: [
                    {
                        path: 'redirect',
                        element: _jsx(Redirect, {}),
                    },
                    {
                        path: 'logout',
                        element: _jsx(Logout, {}),
                    },
                    {
                        path: 'assessment',
                        lazy: Assessment,
                        children: [
                            {
                                path: 'invite',
                                lazy: Invite,
                            },
                            {
                                path: 'assessment-error',
                                lazy: AssessmentError,
                            },
                            // {
                            //   path: 'hardware-check',
                            //   element: <AsyncAnswer />,
                            // },
                            // {
                            //   path: 'guidelines',
                            //   element: <div>guidelines</div>,
                            // },
                            {
                                path: 'instruction',
                                lazy: Instructions,
                            },
                            {
                                path: 'playground',
                                lazy: PlayGround,
                            },
                            {
                                path: 'result',
                                lazy: Submission,
                            },
                            {
                                path: 'submit',
                                lazy: AssessmentSubmission,
                            },
                            {
                                path: 'feedback',
                                lazy: Feedback,
                            },
                            {
                                path: 'thank-you',
                                lazy: Thankyou,
                            },
                        ],
                    },
                    {
                        path: 'payment',
                        lazy: PaymentLayout,
                        children: [
                            {
                                path: 'details',
                                lazy: PaymentSummary,
                            },
                            {
                                path: 'summary',
                                lazy: PaymentDetails,
                            },
                        ],
                    },
                    {
                        path: 'scheduling',
                        lazy: PaymentLayout,
                        children: [
                            {
                                path: 'drive-registration',
                                lazy: RegisterCandidate,
                            },
                            {
                                path: 'slot-booking',
                                lazy: ScheduleNow,
                            },
                            {
                                path: 'booking-confirmation',
                                lazy: BookingConfirm,
                            },
                            {
                                path: 'reschedule-booking',
                                lazy: BookingReSchedule,
                            },
                            {
                                path: 'cancellation-closed',
                                lazy: CancellationWindowClosed,
                            },
                            {
                                path: 'reschedule-closed',
                                lazy: RescheduleWindowClosed,
                            },
                            {
                                path: 'booking-closed',
                                lazy: BookingWindowClosed,
                            },
                            {
                                path: 'session-closed',
                                lazy: SessionAlreadyCompleted,
                            },
                            {
                                path: 'booking-cancel',
                                lazy: BookingWindowCancelled,
                            },
                            {
                                path: 'cancel-booking',
                                lazy: CancelBookingContainer,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
export default router;
