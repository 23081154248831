import { put, call, takeLatest, fork, select } from 'redux-saga/effects';
import { selectQueryParams } from '@containers/Auth/selectors';
import { selectCandidate } from '@containers/CandidateRegistration/selectors';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { REGISTER_CANDIDATE_TO_DRIVE, UPDATE_CANDIDATE_DETAILS_MUTATION } from './queries';
import { updateCandidateRequest, updateCandidateSuccess } from './slice';
import { first, get } from 'lodash';
export function* updateCandidateSagaWorker({ payload }) {
    var _a, _b, _c;
    try {
        const { data } = payload;
        const queryResponse = (yield call(postData, {
            queryString: UPDATE_CANDIDATE_DETAILS_MUTATION,
            payload: data,
        }));
        const candidateData = (_b = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.can_update_candidate) === null || _b === void 0 ? void 0 : _b[0];
        if (candidateData.success) {
            yield put(updateCandidateSuccess({ data: candidateData.data }));
            // Todo: Skip the registerCandidateToDriveWorker if the candidate is already registered to drive
            yield call(registerCandidateToDriveWorker, { payload: { callback: payload === null || payload === void 0 ? void 0 : payload.callback } });
        }
        else {
            throw new Error(candidateData.error_message || 'An error occurred while updating candidate details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Update candidate worker', error: error });
        if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onError) {
            yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onError, error);
        }
    }
}
export function* registerCandidateToDriveWorker({ payload }) {
    var _a, _b, _c, _d, _e;
    try {
        const candidate = (yield select(selectCandidate));
        const queryParams = (yield select(selectQueryParams));
        const formattedPayload = {
            drive_schedule_id: queryParams.driveScheduleId && Number(queryParams.driveScheduleId),
            user_id: candidate.user_id,
            candidate_old_id: candidate.old_id,
            external_id: candidate.external_id,
        };
        const queryResponse = (yield call(postData, {
            queryString: REGISTER_CANDIDATE_TO_DRIVE,
            payload: {
                drive_schedule_id: formattedPayload.drive_schedule_id,
            },
        }));
        const registerGuestResponse = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.sch_register_guest;
        if (registerGuestResponse === null || registerGuestResponse === void 0 ? void 0 : registerGuestResponse.success_count) {
            if ((_b = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _b === void 0 ? void 0 : _b.onSuccess) {
                const schRegisterGuest = first(registerGuestResponse.data);
                const orderItem = first((_d = (_c = schRegisterGuest === null || schRegisterGuest === void 0 ? void 0 : schRegisterGuest.drive_occurrence_guest) === null || _c === void 0 ? void 0 : _c.pay_item) === null || _d === void 0 ? void 0 : _d.order_items);
                const orderId = get(orderItem, 'order_id');
                yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onSuccess, { orderId });
            }
        }
        else {
            throw new Error(JSON.stringify(registerGuestResponse) || 'An error occurred while registering the candidate to drive.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'registerCandidateToDriveWorker', error: error });
        if ((_e = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _e === void 0 ? void 0 : _e.onError) {
            yield call(payload === null || payload === void 0 ? void 0 : payload.callback.onError, error);
        }
    }
}
export function* watchUpdateCandidate() {
    yield takeLatest(updateCandidateRequest.type, updateCandidateSagaWorker);
}
export function* updateCandidateRootSaga() {
    yield fork(watchUpdateCandidate);
}
