import { gql } from '@apollo/client';
export const CREATE_FEEDBACK = gql(`
mutation CreateFeedback($payload: [canx_create_feedback_input]!) {
  canx_create_feedback(data: $payload) {
    data {
      id
      rating
      comment
      type
      user_agent
      instance_id
      model
      region
    }
    success
    error_message
  }
}
`);
