/*
 * Payment helper
 */
import { map } from 'lodash';
export const extractPaymentDetailsData = (paymentResponse) => {
    var _a, _b, _c, _d;
    const paymentId = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.id;
    const orderStatus = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.status;
    const orderItems = map(paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.order_items, ({ item_id, order_id, unit_price, quantity, total_price, item: { status: item_status, drive_occurrence_guest: { id: drive_occurrence_guest_id, drive_schedule: { id: drive_schedule_id, name: drive_schedule_name, start_date: drive_schedule_start_date, end_date: drive_schedule_end_date, slot_duration: drive_schedule_slot_duration, status: drive_schedule_status, }, }, sku: { name: sku_name }, }, }) => ({
        item_id,
        order_id,
        quantity,
        unit_price,
        total_price,
        item_status,
        drive_occurrence_guest_id,
        drive_schedule_id,
        drive_schedule_name,
        drive_schedule_start_date,
        drive_schedule_end_date,
        drive_schedule_slot_duration,
        drive_schedule_status,
        sku_name,
    }));
    const transactionDetails = {
        transactionId: (_a = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.transaction) === null || _a === void 0 ? void 0 : _a.payment_id,
        transactionExternalId: (_b = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.transaction) === null || _b === void 0 ? void 0 : _b.external_id,
        transactionStatus: (_c = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.transaction) === null || _c === void 0 ? void 0 : _c.status,
        transactionTotalAmount: (_d = paymentResponse === null || paymentResponse === void 0 ? void 0 : paymentResponse.transaction) === null || _d === void 0 ? void 0 : _d.total_amount,
    };
    // const driveOccurrenceDetails = {
    //   driveScheduleId: paymentResponse?.drive_occurrence?.drive_schedule_id,
    //   driveEnddate: paymentResponse?.drive_occurrence?.end_date,
    //   driveStartDate: paymentResponse?.drive_occurrence?.start_date,
    //   driveStatus: paymentResponse?.drive_occurrence?.status,
    //   workflowStepId: paymentResponse?.drive_occurrence?.workflow_step_id,
    // };
    const formattedOrderDataArray = orderItems.map((orderItem) => (Object.assign(Object.assign({}, orderItem), { payment_id: paymentId, order_status: orderStatus, transactionDetails })));
    // console.log(formattedOrderDataArray, '::formattedOrderDataArray');
    return formattedOrderDataArray;
};
export const redirectToCXSlotBooking = ({ queryParams, tokens }) => {
    if (!queryParams)
        return;
    const { driveScheduleId, tid } = queryParams;
    const { accessToken } = tokens || {};
    const url = new URL(`${process.env.OLD_CANDIDATE_X_URL}/scheduling/slot-booking`);
    url.searchParams.set('driveScheduleId', String(driveScheduleId));
    url.searchParams.set('tid', String(tid));
    if (accessToken) {
        url.searchParams.set('idToken', accessToken);
    }
    window.open(url.toString(), '_self');
};
