import { gql } from '@apollo/client';
export const GET_ORDER_DETAILS_QUERY = gql `
  query canxGetOrderDetails($payload: [canx_get_order_details_input]!) {
    canx_get_order_details(data: $payload) {
      data {
        id
        status
        order_items {
          item_id
          order_id
          unit_price
          total_price
          quantity
          item {
            id
            status
            drive_occurrence_guest {
              id
              drive_schedule {
                id
                booking_end_date
                booking_start_date
                drive_type
                end_date
                name
                start_date
                status
                timezone
                workflow_step_id
                location
                slot_capacity
                slot_duration
              }
              drive_occurrence {
                drive_schedule_id
                end_date
                start_date
                status
                workflow_step_id
              }
            }
            sku {
              name
            }
          }
        }
        transaction {
          payment_id
          external_id
          status
          total_amount
        }
      }
      success
      error_message
    }
  }
`;
export const CREATE_TRANSACTION = gql `
  mutation createTransaction($orderId: Int!) {
    pay_create_transaction(order_id: $orderId) {
      data {
        order_id
        payment_id
        transaction_external_id
        payment_gateway_type
        payment_url
      }
      success
      error_message
    }
  }
`;
