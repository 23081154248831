var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from '@utils/apollo';
import { processQuery } from '@utils/processQuery';
export const makeRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ variables, queryString, key, forceRefresh = false, context = {} }) {
    const { data } = yield client.query({
        query: queryString,
        variables,
        fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
        context: Object.assign({}, context),
    });
    return key ? data[key] : data;
});
const fetchData = (_a) => __awaiter(void 0, [_a], void 0, function* ({ queryString, queryKey, queryVariables = {}, forceRefresh, context }) {
    if (!queryString) {
        return [];
    }
    const graphQuery = processQuery(queryString);
    return makeRequest({
        variables: Object.assign({}, queryVariables),
        key: queryKey,
        queryString: graphQuery,
        forceRefresh,
        context,
    });
});
export default fetchData;
