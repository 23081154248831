import { authSlice } from '@containers/Auth/slice';
import { combineSlices } from '@reduxjs/toolkit';
import { assessmentSlice } from '@containers/Assessment/slice';
import { meetingSlotsSlice } from '@containers/Payment/SlotBooking/slice';
import { candidateSlice } from '@containers/CandidateRegistration/slice';
import { paymentSlice } from '@containers/Payment/slice';
export default combineSlices({
    [authSlice.name]: authSlice.reducer,
    [assessmentSlice.name]: assessmentSlice.reducer,
    [meetingSlotsSlice.name]: meetingSlotsSlice.reducer,
    [candidateSlice.name]: candidateSlice.reducer,
    [paymentSlice.name]: paymentSlice.reducer,
});
