import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@components/base/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { getAssetUrl } from '@utils/getAssetUrl';
function LazyLoaderErrorBoundary() {
    const error = useRouteError();
    const intl = useIntl();
    const handleContactSupport = () => {
        window.location.reload();
    };
    return (_jsxs("div", { className: "flex flex-col flex-1 justify-center items-center text-center lg:px-32 lg:py-4", children: [_jsx("div", { className: "text-gray-900 md:font-bold lg:text-3xl md:text-xl text-lg font-semibold mb-4 mt-2", children: _jsx(FormattedMessage, Object.assign({}, messages === null || messages === void 0 ? void 0 : messages.heading)) }), _jsx("div", { className: "mb-4", children: _jsx("img", { src: getAssetUrl('connection-lost.png'), className: "h-60 md:h-72 lg:h-80", alt: intl.formatMessage(messages === null || messages === void 0 ? void 0 : messages.errorImage) }) }), _jsxs("div", { className: "px-4 mb-4 font-normal lg:text-lg md:text-base text-sm", children: [isRouteErrorResponse(error) ? (_jsxs(_Fragment, { children: [error.status, " ", error.statusText] })) : (_jsx(_Fragment, { children: (error === null || error === void 0 ? void 0 : error.message) || error })), _jsx("br", {}), _jsx(FormattedMessage, Object.assign({}, messages === null || messages === void 0 ? void 0 : messages.description))] }), _jsx("div", { children: _jsx(Button, { variant: "secondary", className: "rounded", onClick: handleContactSupport, children: _jsx(FormattedMessage, Object.assign({}, messages === null || messages === void 0 ? void 0 : messages.retry)) }) })] }));
}
export default LazyLoaderErrorBoundary;
