import { put, call, fork, debounce, takeEvery } from 'redux-saga/effects';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { GET_ORDER_DETAILS_QUERY, CREATE_TRANSACTION } from './queries';
import { fetchOrderDetailsRequest, fetchOrderDetailsSuccess, createTransactionRequest, createTransactionSuccess, } from './slice';
import { localStorageGetItem, localStorageSetItem, PAYMENT_DETAILS } from '@utils/localStorageHelpers';
import { extractPaymentDetailsData } from '@containers/Payment/payment.helper';
import { first } from 'lodash';
export function* fetchOrderDetailsWorker(action) {
    var _a, _b;
    const { orderId, callback } = action.payload;
    try {
        const storedPaymentDetails = (yield call(localStorageGetItem, PAYMENT_DETAILS));
        const oId = orderId || Number(storedPaymentDetails);
        const paymentOrderResponse = (yield call(postData, {
            queryString: GET_ORDER_DETAILS_QUERY,
            payload: { order_id: oId },
        }));
        const orderDetails = (_b = (_a = paymentOrderResponse === null || paymentOrderResponse === void 0 ? void 0 : paymentOrderResponse.data) === null || _a === void 0 ? void 0 : _a.canx_get_order_details) === null || _b === void 0 ? void 0 : _b[0];
        if (orderDetails.success) {
            yield call(localStorageSetItem, PAYMENT_DETAILS, String(oId));
            const formattedData = (yield call(extractPaymentDetailsData, orderDetails.data));
            const data = first(formattedData);
            yield put(fetchOrderDetailsSuccess(data));
            if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
                yield call(callback.onSuccess, formattedData);
            }
        }
        else {
            throw new Error('An error occurred while fetching order details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Fetch Order Details Worker', error: error });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* createTransactionWorker(action) {
    const { callback } = action.payload;
    try {
        const storedPaymentDetails = (yield call(localStorageGetItem, PAYMENT_DETAILS));
        const orderId = storedPaymentDetails ? Number(storedPaymentDetails) : null;
        const response = (yield call(postData, {
            queryString: CREATE_TRANSACTION,
            payload: { orderId },
            spreadPayload: true,
        }));
        const { data, success, error_message } = response.data.pay_create_transaction;
        if (success) {
            yield put(createTransactionSuccess());
            yield call(localStorageSetItem, PAYMENT_DETAILS, String(data.order_id));
            if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
                yield call(callback.onSuccess, data);
            }
        }
        else {
            throw new Error(error_message || 'Transaction creation failed');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Create Transaction Worker', error: error });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* fetchOrderDetailsSagaWatcher() {
    yield debounce(1000, fetchOrderDetailsRequest.type, fetchOrderDetailsWorker);
}
export function* createTransactionSagaWatcher() {
    yield takeEvery(createTransactionRequest.type, createTransactionWorker);
}
export function* fetchOrderDetailsRootSaga() {
    yield fork(fetchOrderDetailsSagaWatcher);
    yield fork(createTransactionSagaWatcher);
}
