import { triggerToast } from '@components/base/Notification';
import * as Sentry from '@sentry/react';
const timeoutToastContent = {
    title: 'Timeout Error',
    summary: 'The request took too long to process. Please refresh the screen to try again.',
};
export const catchError = (props) => {
    var _a, _b, _c, _d;
    const isTimeOutError = typeof ((_a = props === null || props === void 0 ? void 0 : props.error) === null || _a === void 0 ? void 0 : _a.message) === 'string' ? (_b = props === null || props === void 0 ? void 0 : props.error) === null || _b === void 0 ? void 0 : _b.message.toLowerCase().includes('timeout') : false;
    console.error(`${props === null || props === void 0 ? void 0 : props.title} error: `, (_c = props === null || props === void 0 ? void 0 : props.error) === null || _c === void 0 ? void 0 : _c.message);
    if (!props.skipToast) {
        triggerToast({
            message: {
                title: isTimeOutError ? timeoutToastContent.title : props === null || props === void 0 ? void 0 : props.title,
                summary: isTimeOutError ? timeoutToastContent.summary : (_d = props === null || props === void 0 ? void 0 : props.error) === null || _d === void 0 ? void 0 : _d.message,
            },
            variant: 'danger',
        });
    }
    if (props.extraScope && props.extraScope.key && props.extraScope.value) {
        Sentry.withScope((scope) => {
            if (props.extraScope) {
                const { key, value } = props.extraScope;
                if (key && value) {
                    scope.setTag(key, value);
                }
            }
            Sentry.captureException(props === null || props === void 0 ? void 0 : props.error);
        });
    }
    else {
        Sentry.captureException(props === null || props === void 0 ? void 0 : props.error);
    }
};
export const sentrySetUser = (user) => {
    Sentry.setUser({
        id: ((user === null || user === void 0 ? void 0 : user.user_id) && Number(user === null || user === void 0 ? void 0 : user.user_id)),
        tenant_id: (user === null || user === void 0 ? void 0 : user.tenant_id) && Number(user === null || user === void 0 ? void 0 : user.tenant_id),
    });
};
