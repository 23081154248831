import { gql } from '@apollo/client';
export const UPDATE_CANDIDATE_DETAILS_MUTATION = gql `
  mutation updateCandidateDetails($payload: [can_update_candidate_input]!) {
    can_update_candidate(data: $payload) {
      data {
        country
        email
        external_id
        is_active
        first_name
        id
        is_tnc_accepted
        last_name
        middle_name
        old_id
        user_id
        phone_number
        tenant_id
        is_recording_consent_accepted
      }
      error_message
      success
    }
  }
`;
export const REGISTER_CANDIDATE_TO_DRIVE = gql `
  mutation driveGuestRegistration($payload: [sch_register_guest_input]!) {
    sch_register_guest(data: $payload) {
      data {
        drive_occurrence_guest {
          drive_schedule_id
          guest_id
          id
          pay_item {
            id
            order_items {
              order_id
            }
          }
        }
        guest {
          email
          external_id
          id
          name
          user_id
        }
      }
      success_count
      error_count
    }
  }
`;
