import { call, fork, takeLatest, put, select, debounce } from '@redux-saga/core/effects';
import { catchError } from '@utils/sentry';
import initializeFirebase from '@utils/firebase';
import { initialize, initializeComplete, verifyEmailOtp, getPhoneFirebaseOtpSuccess, verifyFirebaseOtp, getEmailOtpSuccess, getEmailOtp, getPhoneFirebaseOtp, authenticate, authenticateFailed, storeIdToken, storeQueryParams, initializeSocialLogin, storeCurrentUser, storeStoredSession, } from '@containers/Auth/slice';
import { GET_CURRENT_USER, GET_CUSTOM_TOKEN, OTP_REQUEST, SIGNUP, VERIFY_OTP } from './queries';
import postData from '@utils/postData';
import { authenticateFirebase, getAllParams, getStoredSession, signInCredential, signInPhone, initSocialLogin, parseToken, isTokenExpired, getStoredQueryParams, } from './helpers';
import { get } from 'lodash';
import { selectAuth, selectIdToken, selectQueryParams } from './selectors';
import { AUTH_STORAGE_KEY, clearLocalStorage, localStorageSetItem, QUERY_PARAMS_STORAGE_KEY, } from '@utils/localStorageHelpers';
import { fetchCountryCodeApi } from '@utils/fetchCountryCode';
export function* initializeWorker() {
    try {
        yield call(initializeFirebase);
        const params = (yield call(getAllParams));
        if (params.c || params.tid) {
            yield call(clearLocalStorage);
            yield put(storeQueryParams(params));
        }
        else {
            const storedParams = (yield call(getStoredQueryParams));
            yield put(storeQueryParams(Object.assign(Object.assign({}, storedParams), params)));
        }
        const storedSession = (yield call(getStoredSession));
        //if token is not expired means it is authenticated
        if (!isTokenExpired(storedSession === null || storedSession === void 0 ? void 0 : storedSession.accessToken)) {
            // redirect to authenticate
            const parsedToken = (yield call(parseToken, storedSession === null || storedSession === void 0 ? void 0 : storedSession.accessToken));
            yield put(storeStoredSession(storedSession));
            yield put(authenticate({
                accessToken: storedSession === null || storedSession === void 0 ? void 0 : storedSession.accessToken,
                idToken: storedSession === null || storedSession === void 0 ? void 0 : storedSession.idToken,
                customToken: storedSession === null || storedSession === void 0 ? void 0 : storedSession.customToken,
                hash_token: storedSession === null || storedSession === void 0 ? void 0 : storedSession.hash_token,
                tenant_id: storedSession === null || storedSession === void 0 ? void 0 : storedSession.tenant_id,
                parsedToken,
            }));
        }
        else {
            if (params.c || (storedSession === null || storedSession === void 0 ? void 0 : storedSession.hash_token)) {
                yield call(authenticateWorker, {
                    hash_token: (params.c || storedSession.hash_token),
                });
            }
            else if (storedSession === null || storedSession === void 0 ? void 0 : storedSession.idToken) {
                yield put(storeIdToken(storedSession.idToken));
            }
        }
        yield put(initializeComplete());
    }
    catch (error) {
        yield call(catchError, {
            title: 'Auth initializeWorker',
            error: error,
        });
    }
}
export function* getEmailOtpWorker({ payload }) {
    var _a, _b;
    try {
        yield call(postData, {
            queryString: OTP_REQUEST,
            payload: {
                username: payload === null || payload === void 0 ? void 0 : payload.username,
            },
            spreadPayload: true,
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        });
        yield put(getEmailOtpSuccess({ username: payload === null || payload === void 0 ? void 0 : payload.username }));
        if ((_a = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Email Otp Worker',
            error: error,
        });
        if ((_b = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _b === void 0 ? void 0 : _b.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* getPhoneFireBaseOtpWorker({ payload }) {
    try {
        const result = yield call(signInPhone, payload === null || payload === void 0 ? void 0 : payload.phone);
        if (result) {
            const { verificationId } = result;
            yield put(getPhoneFirebaseOtpSuccess({ verificationId, phone: payload === null || payload === void 0 ? void 0 : payload.phone }));
        }
        else {
            // TODO: handle no result case
            throw Error('Not able to generate the Id token');
        }
        if (payload.callback.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Firebase Otp Worker',
            error: error,
        });
        if (payload.callback.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* verifyEmailOtpWorker({ payload }) {
    var _a, _b, _c, _d;
    try {
        const state = (yield select(selectAuth));
        const response = yield call(postData, {
            queryString: VERIFY_OTP,
            payload: {
                username: payload === null || payload === void 0 ? void 0 : payload.username,
                tenant_id: ((_a = state === null || state === void 0 ? void 0 : state.queryParams) === null || _a === void 0 ? void 0 : _a.tid) && Number((_b = state === null || state === void 0 ? void 0 : state.queryParams) === null || _b === void 0 ? void 0 : _b.tid),
                code: payload === null || payload === void 0 ? void 0 : payload.code,
            },
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        });
        const idToken = get(response, 'data.canx_verify_otp.data.id_token');
        if (idToken) {
            yield put(storeIdToken(idToken));
        }
        else {
            throw Error('Not able to generate the Id token');
        }
        if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Verify Otp Worker',
            error: error,
        });
        if ((_d = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _d === void 0 ? void 0 : _d.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* verifyFirebaseOtpWorker({ payload }) {
    var _a, _b, _c;
    try {
        const state = (yield select(selectAuth));
        const result = yield call(signInCredential, state.verificationId, payload === null || payload === void 0 ? void 0 : payload.code);
        if (result) {
            const { user } = result;
            if (user) {
                // @ts-expect-error // TODO: stsTokenManager is not defined in User type custom type have to define
                const stsTokenManage = (_a = user === null || user === void 0 ? void 0 : user.stsTokenManager) === null || _a === void 0 ? void 0 : _a.toJSON();
                yield put(storeIdToken(stsTokenManage === null || stsTokenManage === void 0 ? void 0 : stsTokenManage.accessToken));
            }
            else {
                // TODO: handle no token generation
                throw Error('Failed to generate access token from firebase');
            }
        }
        else {
            // TODO: no result
            throw Error('Not able to sign in with firebase');
        }
        if ((_b = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _b === void 0 ? void 0 : _b.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'firebase verify Otp Worker',
            error: error,
        });
        if ((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* signupWorker() {
    var _a, _b, _c, _d, _e;
    try {
        const idToken = (yield select(selectIdToken));
        const queryParams = (yield select(selectQueryParams));
        const response = (yield call(postData, {
            queryString: SIGNUP,
            payload: {
                id_token: idToken,
                tenant_id: queryParams.tid && Number(queryParams.tid),
            },
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        }));
        const result = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.canx_signup) === null || _b === void 0 ? void 0 : _b[0];
        if (result && result.success) {
            const accessToken = result.data.access_token;
            yield call(localStorageSetItem, AUTH_STORAGE_KEY, JSON.stringify({
                accessToken: accessToken,
                idToken,
                tenant_id: queryParams.tid,
                user_id: (_c = result.data.candidate) === null || _c === void 0 ? void 0 : _c.user_id,
            }));
            const parsedToken = (yield call(parseToken, result.data.access_token));
            yield put(authenticate({
                accessToken: result.data.access_token,
                candidate: result.data.candidate,
                tenant_id: result.data.tenant_id,
                parsedToken,
                idToken,
            }));
        }
        else {
            if ((_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.tenant_id) {
                yield put(storeQueryParams(Object.assign(Object.assign({}, queryParams), { tid: String(result.data.tenant_id) })));
            }
            // TODO: handle no success case. UI should show error message.
            const err = Array.isArray(result === null || result === void 0 ? void 0 : result.error_message) ? (_e = result === null || result === void 0 ? void 0 : result.error_message) === null || _e === void 0 ? void 0 : _e[0] : result === null || result === void 0 ? void 0 : result.error_message;
            throw Error('Failed in signupWorker: ' + err);
        }
    }
    catch (error) {
        yield put(authenticateFailed());
        yield call(catchError, {
            title: 'Authenticate User Worker',
            error: error,
        });
    }
}
export function* authenticateWorker({ hash_token }) {
    var _a, _b;
    try {
        const response = (yield call(postData, {
            queryString: GET_CUSTOM_TOKEN,
            payload: {
                hash_token: hash_token,
            },
            spreadPayload: true,
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        }));
        const customToken = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.auth_get_custom_token) === null || _b === void 0 ? void 0 : _b.custom_token;
        if (customToken) {
            const idToken = (yield call(authenticateFirebase, {
                customToken,
            }));
            // This idToken is the same as accessToken
            if (idToken) {
                yield call(localStorageSetItem, AUTH_STORAGE_KEY, JSON.stringify({
                    accessToken: idToken,
                    idToken,
                    customToken,
                    hash_token,
                }));
                const parsedToken = (yield call(parseToken, idToken));
                yield put(authenticate({
                    accessToken: idToken,
                    parsedToken: parsedToken,
                    hash_token,
                    customToken,
                }));
            }
            else {
                throw Error('Failed to generate idToken from firebase');
            }
        }
        else {
            throw Error('Not able to generate the custom token.');
        }
    }
    catch (error) {
        yield put(authenticateFailed());
        yield call(catchError, {
            title: 'Authenticate Worker',
            error: error,
        });
    }
}
export function* socialLoginWorker({ payload }) {
    var _a, _b, _c;
    try {
        const response = (yield call(initSocialLogin, payload.socialLoginProviderName));
        if (response && (response === null || response === void 0 ? void 0 : response.idToken)) {
            yield put(storeIdToken(response.idToken));
            if ((_a = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _a === void 0 ? void 0 : _a.onSuccess) {
                yield call(payload.callback.onSuccess);
            }
        }
        else {
            throw Error('Id token not found');
        }
    }
    catch (error) {
        if ((_b = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _b === void 0 ? void 0 : _b.onError) {
            yield call((_c = payload === null || payload === void 0 ? void 0 : payload.callback) === null || _c === void 0 ? void 0 : _c.onError);
        }
        yield call(catchError, {
            title: 'firebase social login worker',
            error: error,
        });
    }
}
export function* storeQueryParamsWorker() {
    try {
        const params = yield select(selectQueryParams);
        yield call(localStorageSetItem, QUERY_PARAMS_STORAGE_KEY, JSON.stringify(params));
    }
    catch (error) {
        yield call(catchError, {
            title: 'storeQueryParamsWorker',
            error: error,
        });
    }
}
export function* storeCurrentUserSagaWorker() {
    var _a;
    try {
        const queryResponse = (yield call(postData, {
            queryString: GET_CURRENT_USER,
            payload: {},
        }));
        const countryCode = (yield call(fetchCountryCodeApi));
        const currentUser = (_a = queryResponse === null || queryResponse === void 0 ? void 0 : queryResponse.data) === null || _a === void 0 ? void 0 : _a.auth_user_me;
        if (currentUser) {
            currentUser.countryCode = countryCode;
        }
        yield put(storeCurrentUser(currentUser));
    }
    catch (error) {
        yield call(catchError, {
            title: 'Fetch Current User Worker',
            error: error,
        });
    }
}
export function* initializeWatcher() {
    yield debounce(1000, initialize.type, initializeWorker);
}
export function* getEmailOtpWatcher() {
    yield takeLatest(getEmailOtp === null || getEmailOtp === void 0 ? void 0 : getEmailOtp.type, getEmailOtpWorker);
}
export function* getPhoneFirebaseOtpWatcher() {
    yield takeLatest(getPhoneFirebaseOtp === null || getPhoneFirebaseOtp === void 0 ? void 0 : getPhoneFirebaseOtp.type, getPhoneFireBaseOtpWorker);
}
export function* verifyEmailOtpWatcher() {
    yield takeLatest(verifyEmailOtp.type, verifyEmailOtpWorker);
}
export function* verifyFirebaseOtpWatcher() {
    yield takeLatest(verifyFirebaseOtp.type, verifyFirebaseOtpWorker);
}
export function* signupWatcher() {
    yield takeLatest([storeIdToken.type], signupWorker);
}
export function* socialLoginWatcher() {
    yield takeLatest(initializeSocialLogin.type, socialLoginWorker);
}
export function* storeQueryParamsWatcher() {
    yield takeLatest(storeQueryParams.type, storeQueryParamsWorker);
}
export function* storeCurrentUserSagaWatcher() {
    yield takeLatest(authenticate.type, storeCurrentUserSagaWorker);
}
export default function* authRootSaga() {
    yield fork(initializeWatcher);
    yield fork(getEmailOtpWatcher);
    yield fork(getPhoneFirebaseOtpWatcher);
    yield fork(verifyEmailOtpWatcher);
    yield fork(verifyFirebaseOtpWatcher);
    yield fork(signupWatcher);
    yield fork(socialLoginWatcher);
    yield fork(storeQueryParamsWatcher);
    yield fork(storeCurrentUserSagaWatcher);
}
