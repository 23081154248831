var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { processQuery } from '@utils/processQuery';
import client from '@utils/apollo';
export const makeRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ payload, queryString, context = {}, spreadPayload }) {
    return client.mutate({
        mutation: queryString,
        variables: spreadPayload ? Object.assign({}, payload) : { payload },
        context: Object.assign({}, context),
    });
});
const postData = (_a) => __awaiter(void 0, [_a], void 0, function* ({ queryString, payload, spreadPayload, context }) {
    if (!queryString) {
        throw Error('queryString not provided');
    }
    const graphQuery = processQuery(queryString);
    return makeRequest({
        payload,
        queryString: graphQuery,
        spreadPayload,
        context,
    });
});
export default postData;
