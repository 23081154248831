import { defineMessages } from 'react-intl';
export default defineMessages({
    login: {
        id: 'app.containers.Auth.login',
        defaultMessage: 'Login',
    },
    logout: {
        id: 'app.containers.Auth.logout',
        defaultMessage: 'Logout',
    },
});
