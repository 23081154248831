import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    candidate: null,
};
export const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        updateCandidateRequest: (_state, _action) => { },
        updateCandidateSuccess: (state, action) => {
            state.candidate = action.payload.data;
        },
    },
});
export const { updateCandidateRequest, updateCandidateSuccess } = candidateSlice.actions;
export default candidateSlice.reducer;
