import { GrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';
import { catchError } from '@utils/sentry';
export let growthBook;
const initializeGrowthBook = () => {
    try {
        growthBook = new GrowthBook({
            apiHost: process.env.GROWTHBOOK_API_HOST,
            clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
            enableDevMode: process.env.NODE_ENV !== 'production',
            trackingCallback(experiment, result) {
                Mixpanel.track('$experiment_started', {
                    'Experiment name': experiment.key,
                    'Variant name': result.variationId,
                    $source: 'growthbook',
                });
            },
        });
        if (process.env.NODE_ENV !== 'test') {
            growthBook.init({
                streaming: true,
            });
        }
        return growthBook;
    }
    catch (error) {
        catchError({ title: 'GrowthBook initialization', error: error });
        return growthBook;
    }
};
initializeGrowthBook();
export default initializeGrowthBook;
